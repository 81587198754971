<template>
    <div :class="isHidden ? 'nav-wrapper nav-wrapper-hidden' : 'nav-wrapper nav-wrapper-show'">
        <div class="nav-header">
            <div class="nav-left">
                <img :src="env !== 'production' ? require('../assets/pc/logo_dev.png') : require('../assets/pc/logo.png')"  class="logo-img"/>
                <div class="title">优品利购</div>
            </div>
            <img src="../assets/mobile/nav.png" class="nav-img" v-if="isHidden" @click="handleNav(false)"/>
            <img src="../assets/mobile/close.png" class="close-img" v-else @click="handleNav(true)"/>
        </div>
        <div class="nav-body" v-if="!isHidden">
            <div v-for="(item,index) in list" :key="index" :class="currentIndex === index ? 'nav-item nav-item-selected' : 'nav-item'" @click="handleChangeNav(index)">
                {{item.title}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "navbar",
        props:['currentIndex'],
        data(){
            return {
                env: process.env.VUE_APP_ENV,
                isHidden: true,
                list:[
                    {
                        title: '首页'
                    },
                    {
                        title: '玩法介绍'
                    },
                    {
                        title: '关于我们'
                    },
                    {
                        title: '联系我们'
                    }
                ],
            }
        },
        mounted(){
            //刷新浏览器时，根据路由hash确定选中的导航栏和swipe
            const hash= window.location.hash;
            if(hash.indexOf('introduce')>-1){
                this.$emit('changeNav',1);
            } else if(hash.indexOf('about')>-1){
                this.$emit('changeNav',2);
            } else if(hash.indexOf('relate')>-1){
                this.$emit('changeNav',3);
            }else{
                this.$emit('changeNav',0);
            }
        },
        methods:{
            handleNav(val){
                this.isHidden = val;
            },
            handleChangeNav(index){
                // this.currentIndex = index;
                this.$emit('changeNav',index);
                this.isHidden = true;
            }
        }
    }
</script>

<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin flex($ai:center){
        display: flex;
        align-items: $ai;
    }
    .nav-wrapper{
        display: flex;
        flex-direction: column;
        z-index: 9999;
        background: #fff;

        .nav-header{
            @include width-height(100%,88px);
            padding: 0 25px;
            @include flex;
            justify-content: space-between;
            box-sizing: border-box;
            border-bottom: 1px solid #CFCFCF;

            .nav-left{
                font-size: 22px;
                font-weight: 400;
                color: #080808;
                @include flex;

                .logo-img{
                    @include width-height(48px,48px);
                    margin-right: 10px;
                }
            }
            .nav-img{
                @include width-height(42px,35px);
            }
            .close-img{
                @include width-height(32px,32px);
            }
        }
        .nav-body{
            flex: 1;
            padding: 0 40px;

            .nav-item{
                padding: 25px 0;
                border-bottom: 1px solid #CFCFCF;
                font-size: 28px;
                font-weight: 400;
                color: #8E8E8E;
            }
            .nav-item-selected{
                color:#E6305C;
            }
        }
    }
    .nav-wrapper-hidden{
        position: relative;
    }
    .nav-wrapper-show{
        position: absolute;
        @include width-height;
    }
</style>
