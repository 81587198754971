<template>
    <div class="about-wrapper">
        <img src="../assets/mobile/about/about.png" class="about-img"/>
        <div class="text-block">
            <div class="title">关于优品利购</div>
            <div class="content">优品利购是一款社交分享智能导购APP，通过与全国数家大型电商平台与海量商家合作，以高质量的消费类内容向用户介绍高性价比、好口碑的商品。新版的优品利购增加了外卖点餐、美食旅行、本地生活，飞猪机票等服务，满足您衣食住行各方面的需求。</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "about"
    }
</script>

<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 48px,$color:#fff,$weight:500){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    @mixin flex($ai:center){
        display: flex;
        align-items: $ai;
    }
    .about-wrapper{
        @include width-height;
        background-image: url('../assets/mobile/about/bg.png');
        background-size:100% 100%;
        padding: 0 20px;

        .about-img{
            @include width-height(100%,750px);
        }
        .text-block{
            padding: 0 88px;

            .title{
                @include font;
                margin-bottom: 40px;
                text-align: center;
            }
            .content{
                @include font(24px,#fff,300);
                line-height: 48px;
            }
        }
    }
</style>
