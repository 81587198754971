<template>
    <div class="record">
        <a :style="{'color':color}" @click="handleGoTo('https://beian.miit.gov.cn/#/Integrated/index')">Copyright  2019-2022深圳市精明购科技有限公司 粤ICP备2020117576号</a>
        <a @click="handleGoTo('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402004256')" :style="{'color':color}">
            粤公网安备44030402004256号
            <img src="../assets/pc/police.png"/>
        </a>
    </div>
</template>

<script>
    export default {
        name: "record",
        props:['color'],
        methods:{
            handleGoTo(url){
                window.open(url,'target','');
            }
        }
    }
</script>

<style scoped lang="scss">
    .record{
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;

        a{
            font-size: 18px!important;
            font-weight: 400;
            &:last-child{
                display: flex;
                align-items: center;
            }

            img{
                width: 28px;
                height: 28px;
                margin-left:2px;
            }
        }
    }
</style>

